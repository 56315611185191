import { ErrorType } from '../constants/errors';
import { getAuthHeader } from './auth';
import { ResponseError } from './errors';

const ApiMode = {
  Prod: 0,
  Dev: 1,
  Local: 2,
  Abo: 3,
  Dro: 4,
};

const apiMode = ApiMode.Prod;

export const getCurrentUrl = (path, stripSearch = false) => {
  const url = new URL(window.location.href);
  if (stripSearch) url.search = '';
  if (!path) return url;
  const _path = path.startsWith('/') ? path : `/${path}`;
  url.pathname = _path;
  return url;
};

// Source: react-boilerplate => https://github.com/react-boilerplate/react-boilerplate  (app/utils/request.js)

/**
 * Build backend url
 *
 * @returns {string} Returns a url as string
 */
const buildBaseUrl = (protocol, withOrg = true) => {
  const url = getCurrentUrl();
  let { hostname } = url;
  if (!withOrg) {
    const splitted = hostname.split('.');
    if (splitted.length >= 3) {
      const env = splitted[splitted.length - 3];
      if (['local', 'dev', 'demo'].includes(env)) {
        // env found in url, taking it
        hostname = splitted.slice(splitted.length - 3, splitted.length).join('.');
      } else {
        // no env found in url
        hostname = splitted.slice(splitted.length - 2, splitted.length).join('.');
      }
    }
  }
  const result = `${protocol}//api.${hostname}`;
  return result;
};

/**
 * Get the current backend base (root) url
 *
 * @returns {string} Returns a url as string
 */
export const getBaseUrl = (withOrg = true) => {
  const url = getCurrentUrl();
  if (apiMode === ApiMode.Local)
    return `http://localhost:8080${withOrg ? '/606ee9c4bf11b4001c9ac54b' : ''}`;
  if (apiMode === ApiMode.Dro)
    return `https://dro-1.dev.kalyzee.com${withOrg ? '/606ee9c4bf11b4001c9ac54b' : ''}`;
  if (apiMode === ApiMode.Abo)
    return `https://abo.dev.kalyzee.com${withOrg ? '/606ee9c4bf11b4001c9ac54b' : ''}`;
  if (apiMode === ApiMode.Dev) return `https://api.kalyzee.dev.kast.app`;
  return buildBaseUrl(url.protocol, withOrg);
};

const addToPathNameUrl = (url, suffixPathName) => {
  const urlObj = new URL(url);
  let suffix = suffixPathName;
  if (urlObj.pathname.endsWith('/') && suffixPathName.startsWith('/')) {
    suffix = suffixPathName.replace('/', '');
  }
  urlObj.pathname += suffix;
  return urlObj.href;
};

/**
 * Get the current backend base (root) url depending on env variable
 *
 * @returns {string} Returns a url as string
 */
const getSocketBaseUrl = () => {
  const url = getCurrentUrl();
  if (apiMode === ApiMode.Local)
    return `http://localhost:8080/?organization=606ee9c4bf11b4001c9ac54b`;
  if (apiMode === ApiMode.Dro)
    return `wss://dro-1.dev.kalyzee.com/?organization=606ee9c4bf11b4001c9ac54b`;
  if (apiMode === ApiMode.Abo)
    return `wss://abo.dev.kalyzee.com/?organization=606ee9c4bf11b4001c9ac54b`;
  if (apiMode === ApiMode.Dev) return `wss://api.kalyzee.dev.kast.app`;
  return `${buildBaseUrl(url.protocol.replace('http', 'ws'))}`; // Keep same security level : http -> ws & https -> wss
};

export const getUserSocketUrl = () => addToPathNameUrl(getSocketBaseUrl(), '/user');
export const getWebRTCSocketUrl = () => addToPathNameUrl(getSocketBaseUrl(), '/webrtc');
/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  throw new ResponseError(ErrorType.API_ERROR, response);
};

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
const parseJSON = (response) => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
};

const handleError = (error) => {
  if (error.errorType) throw error;
  throw new ResponseError(ErrorType.NETWORK_ERROR);
};

const buildQueryParams = (params) => {
  const encodeKeyVal = (key, val) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
  return Object.keys(params)
    .map((key) => {
      if (Array.isArray(params[key]))
        return params[key].map((val) => encodeKeyVal(`${key}[]`, val)).join('&');
      return encodeKeyVal(key, params[key]);
    })
    .join('&');
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
const request = (url, options) => {
  return fetch(url, options).then(checkStatus).then(parseJSON).catch(handleError);
};

/**
 * Request an URL and return a promise
 *
 * @param {string} path The URL to request
 * @param {object} options Options to use for the request
 * @param {object} [queryParams] Query Params to add the request url (handle array params)
 *
 * @returns {object} Promise with the response data
 */
export const requestAPI = (path, options, queryParams = {}, withOrg = true) => {
  let url = `${getBaseUrl(withOrg)}${path}`;
  if (queryParams && Object.keys(queryParams).length > 0 && queryParams.constructor === Object)
    url += `?${buildQueryParams(queryParams)}`;
  return request(url, options);
};

export const clientRedirect = (path, queryParams = {}) => {
  let url = `${getBaseUrl()}${path}`;
  if (queryParams && Object.keys(queryParams).length > 0 && queryParams.constructor === Object)
    url += `?${buildQueryParams(queryParams)}`;
  window.location.href = url;
};

/**
 * Get the header of request
 *
 * @param  {string} method   Method of request ['GET','POST','PATCH', ...]
 * @param  {boolean} authentificated   Send token in header if true. True by default
 *
 * @returns {string} Returns header
 */
export const getHeadersAPI = (method, authentificated = true, body = null) => {
  const header = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (body) header.body = body;
  if (authentificated) {
    const authHeader = getAuthHeader(); // { Authorization: Bearer xyz }
    header.headers = { ...header.headers, ...authHeader };
  }
  return header;
};

export default {
  requestAPI,
  getHeadersAPI,
  getBaseUrl,
  getUserSocketUrl,
  getWebRTCSocketUrl,
};
