import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Image, ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';

import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';

import vodActions from '../../App/actions/vod';
import userSelectors from '../../App/selectors/user';

import { isErrorVodState, VodState } from '../../constants/vod';
import { getPlayerMessageData, MESSAGE_CRITICITY } from '../../helpers/player-messages';
import { userRoleIsGTE } from '../../helpers/role';
import { USER_ROLE } from '../../constants/global';

import { Strings } from '../../helpers/strings';

const styles = StyleSheet.create({
  mainContainer: {
    overflow: 'hidden',
    padding: 20,
    borderRadius: 20,
    backgroundColor: colors.getGrayAthens(),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  center: {
    alignItems: 'center',
  },
  taskList: {
    gap: 5,
  },
  taskMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    borderRadius: 4,
    paddingRight: 3,
  },
  taskBox: {
    borderRadius: 4,
    padding: 3,
    paddingVertical: 2,
  },
  taskMessageText: {
    color: 'white',
    fontFamily: fonts.getSignika(),
    fontSize: 15,
    fontWeight: 800,
  },
  dateText: {
    fontFamily: fonts.getSignika(),
    color: colors.getBluePrussian(),
    fontSize: 18,
    fontWeight: 700,
  },
  statusText: {
    fontFamily: fonts.getSignika(),
    fontSize: 16,
    fontWeight: 700,
  },
});

const imageUrl =
  'https://img.freepik.com/premium-photo/school-classroom-with-chairsdesks-chalkboard-without-student_258219-264.jpg?w=1380';

const VodCard = ({ vodSession, onClick, style, role, recoverVod }) => {
  const { t } = useTranslation();
  const [userClicked, setUserClicked] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [waitingUpdate, setWaitingUpdate] = useState(false);

  useEffect(() => {
    setIsAdmin(role && userRoleIsGTE(role, USER_ROLE.ORGANIZER));
  }, [role]);

  useEffect(() => {
    if (userClicked && !isAdmin) {
      const id = setInterval(() => {
        setUserClicked(false);
      }, 3000);
      return () => clearInterval(id);
    }
    return () => {};
  }, [userClicked]);

  useEffect(() => {
    setWaitingUpdate(false);
    setUserClicked(false);
  }, [vodSession]);

  const renderDate = () => {
    const date = vodSession.recordStartedAt;
    let dateString = moment(date).format('dddd DD MMMM - HH:mm');
    dateString = dateString.charAt(0).toUpperCase() + dateString.slice(1);
    return <Text style={styles.dateText}>{dateString}</Text>;
  };

  const renderStatus = () => {
    const renderWaitingUpdate = () => {
      return (
        <View style={[styles.row, styles.center, { gap: 5 }]}>
          <ActivityIndicator />
          <Text style={[styles.statusText, { color: 'orange' }]}>
            {t(Strings.ATTEMPTING_RESOLUTION)}
          </Text>
        </View>
      );
    };

    const renderStatusLine = () => {
      const stateData = getPlayerMessageData(vodSession.state);
      const { state } = vodSession;

      let color = colors.getMainRed(); // Default
      let message = Strings.VOD_UNKNOW_STATE;
      let icon = null;
      if (stateData) {
        message = t(userClicked ? Strings.VIDEO_NOT_AVAILABLE_YET : stateData.message);
        icon = stateData.icon;
        if (state === VodState.Terminated) {
          color = colors.getMountainMeadow();
        } else if (stateData.criticity === MESSAGE_CRITICITY.ERROR) {
          color = colors.getMainRed();
        } else {
          color = colors.getGrayDusty();
        }
      }

      return (
        <View style={[styles.row, styles.center, { gap: 5, flex: 1 }]}>
          {icon ? React.createElement(icon, { style: { height: 25 } }) : null}
          <Text style={[styles.statusText, { color }]}>{message}</Text>
        </View>
      );
    };

    return (
      <View style={[styles.row, styles.center]}>
        {waitingUpdate ? renderWaitingUpdate() : renderStatusLine()}
      </View>
    );
  };

  const onUserClickCard = () => {
    if (vodSession.state === VodState.Terminated) {
      if (onClick && typeof onClick === 'function') onClick();
    } else {
      setWaitingUpdate(true);
      recoverVod(vodSession.id);
      setUserClicked(!userClicked);
    }
  };

  return (
    <TouchableOpacity
      disabled={
        !(
          vodSession.state === VodState.Terminated ||
          (isAdmin && isErrorVodState(vodSession.state))
        )
      }
      onPress={onUserClickCard}
    >
      <View style={[styles.mainContainer, style]}>
        <View style={[styles.row, styles.center]}>
          <View style={styles.col}>
            <Image style={{ width: '16vmin', height: '9vmin' }} source={{ uri: imageUrl }} />
          </View>
          <View style={[styles.col, { marginLeft: '5px', flex: 1 }]}>
            {renderDate()}
            {renderStatus()}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

VodCard.propTypes = {
  role: PropTypes.string.isRequired,
  style: PropTypes.object,
  vodSession: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  recoverVod: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  role: userSelectors.makeSelectRole(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    recoverVod: (vodId) => dispatch(vodActions.recover(vodId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VodCard);
