import { VodState } from '../constants/vod';

import IconStatusRecording from '../assets/activities/icon-videocam-outline.svg';
import IconStatusUploading from '../assets/activities/icon-swap-vertical-outline.svg';
import IconStatusPublishing from '../assets/activities/icon-cloud-upload-outline.svg';
import IconStatusSuccess from '../assets/activities/icon-checkmark-circle-outline.svg';
import IconStatusTrash from '../assets/activities/icon-trash.svg';
import { Strings } from './strings';
import { ACTIVITY_COUNTDOWN } from '../constants/activity';

export const MESSAGE_CRITICITY = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
};

const messageData = {
  [VodState.Idle]: { message: Strings.VOD_IDLE, criticity: MESSAGE_CRITICITY.SUCCESS, icon: null },
  [VodState.Starting]: {
    message: Strings.VOD_STARTING,
    criticity: MESSAGE_CRITICITY.SUCCESS,
    icon: null,
  },
  [VodState.RecordOngoing]: {
    category: Strings.VOD,
    message: Strings.VOD_RECORD_ONGOING,
    criticity: MESSAGE_CRITICITY.SUCCESS,
    icon: IconStatusRecording,
  },
  [VodState.RecordStopWaitingDevice]: {
    category: Strings.VOD,
    message: Strings.VOD_RECORD_STOP_WAITING_DEVICE,
    criticity: MESSAGE_CRITICITY.ERROR,
    icon: IconStatusRecording,
  },
  [VodState.RecordStopError]: {
    category: Strings.VOD,
    message: Strings.VOD_RECORD_STOP_ERROR,
    criticity: MESSAGE_CRITICITY.ERROR,
    icon: IconStatusRecording,
  },
  [VodState.RecordError]: {
    category: Strings.VOD,
    message: Strings.VOD_RECORD_ERROR,
    criticity: MESSAGE_CRITICITY.ERROR,
    icon: IconStatusRecording,
  },
  [VodState.UploadStartWaitingDevice]: {
    category: Strings.VOD,
    message: Strings.VOD_UPLOAD_START_WAITING_DEVICE,
    criticity: MESSAGE_CRITICITY.ERROR,
    icon: IconStatusUploading,
  },
  [VodState.UploadStartError]: {
    category: Strings.VOD,
    message: Strings.VOD_UPLOAD_START_ERROR,
    criticity: MESSAGE_CRITICITY.ERROR,
    icon: IconStatusUploading,
  },
  [VodState.UploadOngoing]: {
    category: Strings.VOD,
    message: Strings.VOD_UPLOAD_ONGOING,
    criticity: MESSAGE_CRITICITY.SUCCESS,
    icon: IconStatusUploading,
  },
  [VodState.UploadError]: {
    category: Strings.VOD,
    message: Strings.VOD_UPLOAD_ERROR,
    criticity: MESSAGE_CRITICITY.ERROR,
    icon: IconStatusUploading,
  },
  [VodState.PublishStartWaitingService]: {
    category: Strings.VOD,
    message: Strings.VOD_PUBLISH_START_WAITING_SERVICE,
    criticity: MESSAGE_CRITICITY.ERROR,
    icon: IconStatusPublishing,
  },
  [VodState.PublishStartError]: {
    category: Strings.VOD,
    message: Strings.VOD_PUBLISH_START_ERROR,
    criticity: MESSAGE_CRITICITY.ERROR,
    icon: IconStatusPublishing,
  },
  [VodState.PublishOngoing]: {
    category: Strings.VOD,
    message: Strings.VOD_PUBLISH_ONGOING,
    criticity: MESSAGE_CRITICITY.SUCCESS,
    icon: IconStatusPublishing,
  },
  [VodState.PublishError]: {
    category: Strings.VOD,
    message: Strings.VOD_PUBLISH_ERROR,
    criticity: MESSAGE_CRITICITY.ERROR,
    icon: IconStatusPublishing,
  },
  [VodState.Terminated]: {
    category: Strings.VOD,
    message: Strings.VIDEO_AVAILABLE_ON_KALYZEE,
    criticity: MESSAGE_CRITICITY.SUCCESS,
    icon: IconStatusSuccess,
  },
  [VodState.DeleteError]: {
    category: Strings.VOD,
    message: Strings.VOD_DELETE_ERROR,
    criticity: MESSAGE_CRITICITY.ERROR,
    icon: IconStatusTrash,
  },
  [VodState.Deleting]: {
    category: Strings.VOD,
    message: Strings.VOD_DELETE_ONGOING,
    criticity: MESSAGE_CRITICITY.SUCCESS,
    icon: IconStatusTrash,
  },
  [VodState.Deleted]: {
    category: Strings.VOD,
    message: Strings.VOD_DELETED,
    criticity: MESSAGE_CRITICITY.SUCCESS,
    icon: IconStatusTrash,
  },
  [ACTIVITY_COUNTDOWN.FIVE_MINUTES]: {
    category: Strings.ACTIVITY,
    message: Strings.ACTIVITY_COUNTDOWN_FIVE_MINUTES,
    criticity: MESSAGE_CRITICITY.INFO,
  },
  [ACTIVITY_COUNTDOWN.ONE_MINUTE]: {
    category: Strings.ACTIVITY,
    message: Strings.ACTIVITY_COUNTDOWN_ONE_MINUTE,
    criticity: MESSAGE_CRITICITY.INFO,
  },
  [ACTIVITY_COUNTDOWN.TEN_SECONDS]: {
    category: Strings.ACTIVITY,
    message: Strings.ACTIVITY_COUNTDOWN_TEN_SECONDS,
    criticity: MESSAGE_CRITICITY.INFO,
  },
};

export const getPlayerMessageData = (state) => {
  return messageData[state];
};

export default {
  getPlayerMessageData,
};
